var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticStyle:{"height":"calc(98vh - 50px)","margin-top":"1vh"},attrs:{"footer-class":"m-0","body-class":"py-1 soft-primary payment-card-body"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Keyboard',{attrs:{"save-text":_vm.translate.translate('frequentlyRepeated', 'soxranit')},on:{"addNum":_vm.addNum,"toNextField":_vm.toNextField,"del":_vm.del,"clear":_vm.clear,"save":_vm.saveRefund}})]},proxy:true}])},[_c('div',{staticClass:"p-0"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.translate.translate("frequentlyRepeated", "koplate"))+": ")]),_c('h4',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(_vm._f("decimal")(_vm.total))+" "+_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.Name : '')+" ")])]),_c('b-form-group',{staticClass:"mt-3"},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.translate.translate("frequentlyRepeated", "oplataNalichnimi")))]),_c('b-input-group',[_c('AppendCurrencyToInput',{attrs:{"price-currency-value":_vm.refund.CashbackCurrencyValue}}),_c('IMaskPriceInput',{ref:"CashbackCurrencyValue",class:{ 'is-invalid': _vm.$v.refund.CashbackCurrencyValue.Value.$error },on:{"focus":function($event){return _vm.onFocusToPaymentInput(_vm.refund.CashbackCurrencyValue, 'CashbackCurrencyValue')},"blur":() => { if (!_vm.refund.CashbackCurrencyValue.Value ) _vm.refund.CashbackCurrencyValue.Value = 0}},model:{value:(_vm.refund.CashbackCurrencyValue.Value),callback:function ($$v) {_vm.$set(_vm.refund.CashbackCurrencyValue, "Value", $$v)},expression:"refund.CashbackCurrencyValue.Value"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"my-primary",on:{"click":() => {
                                            _vm.refund.CashbackCurrencyValue.Value = _vm.total;
                                            _vm.refund.DebtPaymentCurrencyValue.Value = 0;
                                         }}},[_vm._v(_vm._s(_vm.translate.translate("frequentlyRepeated", "vsyaSumma"))+" ")])],1)],1)],1)],1),_c('b-form-group',{staticClass:"mt-3"},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.translate.translate("frequentlyRepeated", "SpisaniyeSoSchetaKlienta")))]),_c('b-input-group',[_c('AppendCurrencyToInput',{attrs:{"price-currency-value":_vm.refund.DebtPaymentCurrencyValue}}),_c('IMaskPriceInput',{ref:"DebtPaymentCurrencyValue",class:{ 'is-invalid': _vm.$v.refund.DebtPaymentCurrencyValue.Value.$error },on:{"focus":function($event){return _vm.onFocusToPaymentInput(_vm.refund.DebtPaymentCurrencyValue, 'DebtPaymentCurrencyValue')},"blur":() => { if (!_vm.refund.DebtPaymentCurrencyValue.Value ) _vm.refund.DebtPaymentCurrencyValue.Value = 0}},model:{value:(_vm.refund.DebtPaymentCurrencyValue.Value),callback:function ($$v) {_vm.$set(_vm.refund.DebtPaymentCurrencyValue, "Value", $$v)},expression:"refund.DebtPaymentCurrencyValue.Value"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"my-primary",on:{"click":() => {
                                            _vm.refund.DebtPaymentCurrencyValue.Value = _vm.total;
                                            _vm.refund.CashbackCurrencyValue.Value = 0;
                                         }}},[_vm._v(_vm._s(_vm.translate.translate("frequentlyRepeated", "vsyaSumma"))+" ")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-between mt-3 align-items-center"},[_c('p',[_vm._v("Распечатать чек")]),_c('switches',{staticClass:"vue-switcher-small",attrs:{"id":"tool-mode-switch","theme":"custom","color":"primary"},model:{value:(_vm.printCheck),callback:function ($$v) {_vm.printCheck=$$v},expression:"printCheck"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }