<template>
    <b-card footer-class="m-0" body-class="py-1 soft-primary payment-card-body"
            style="height: calc(98vh - 50px); margin-top: 1vh;">
        <div class="p-0">
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span>
                    {{ translate.translate("frequentlyRepeated", "koplate") }}:
                </span>
                <h4 class="fw-bold">
                    {{ total | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : '' }}
                </h4>
            </div>
            <b-form-group class="mt-3">
                <b-form-group>
                    <label>{{ translate.translate("frequentlyRepeated", "oplataNalichnimi") }}</label>
                    <b-input-group>
                        <AppendCurrencyToInput :price-currency-value="refund.CashbackCurrencyValue"/>
                        <IMaskPriceInput v-model="refund.CashbackCurrencyValue.Value"
                                         ref="CashbackCurrencyValue"
                                         :class="{ 'is-invalid': $v.refund.CashbackCurrencyValue.Value.$error }"
                                         @focus="onFocusToPaymentInput(refund.CashbackCurrencyValue, 'CashbackCurrencyValue')"
                                         @blur="() => { if (!refund.CashbackCurrencyValue.Value ) refund.CashbackCurrencyValue.Value = 0}"/>
                        <b-input-group-append>
                            <b-button class="my-primary" @click="() => {
                                                refund.CashbackCurrencyValue.Value = total;
                                                refund.DebtPaymentCurrencyValue.Value = 0;
                                             }">{{ translate.translate("frequentlyRepeated", "vsyaSumma") }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-form-group>
            <b-form-group class="mt-3">
                <b-form-group>
                    <label>{{ translate.translate("frequentlyRepeated", "SpisaniyeSoSchetaKlienta") }}</label>
                    <b-input-group>
                        <AppendCurrencyToInput :price-currency-value="refund.DebtPaymentCurrencyValue"/>
                        <IMaskPriceInput v-model="refund.DebtPaymentCurrencyValue.Value"
                                         ref="DebtPaymentCurrencyValue"
                                         :class="{ 'is-invalid': $v.refund.DebtPaymentCurrencyValue.Value.$error }"
                                         @focus="onFocusToPaymentInput(refund.DebtPaymentCurrencyValue, 'DebtPaymentCurrencyValue')"
                                         @blur="() => { if (!refund.DebtPaymentCurrencyValue.Value ) refund.DebtPaymentCurrencyValue.Value = 0}"/>
                        <b-input-group-append>
                            <b-button class="my-primary" @click="() => {
                                                refund.DebtPaymentCurrencyValue.Value = total;
                                                refund.CashbackCurrencyValue.Value = 0;
                                             }">{{ translate.translate("frequentlyRepeated", "vsyaSumma") }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-form-group>
            <div class="d-flex justify-content-between mt-3 align-items-center">
                <p>Распечатать чек</p>
                <switches id="tool-mode-switch" v-model="printCheck" theme="custom" class="vue-switcher-small"
                          color="primary"/>
            </div>
        </div>
        <template #footer>
            <Keyboard @addNum="addNum" @toNextField="toNextField" @del="del" @clear="clear" @save="saveRefund"
                      :save-text="translate.translate('frequentlyRepeated', 'soxranit')"/>
        </template>
    </b-card>
</template>

<script>
import AppendCurrencyToInput from "../currency/AppendCurrencyToInput";
import IMaskPriceInput from "../imask/IMaskPriceInput";
import translate from "../../translation/translate";
import {decimal, minValue, required} from "vuelidate/lib/validators";
import Downloader from "../../downloader";
import RefundService from "../../services/refund/refund.service";
import {validationMixin} from "vuelidate";
import {mapGetters} from "vuex";
import Switches from 'vue-switches';
import Keyboard from "../additional/Keyboard";
import math from "../../plugins/math";

export default {
    name: "RefundPaymentFormComponent",
    components: {Keyboard, IMaskPriceInput, AppendCurrencyToInput, Switches},
    mixins: [validationMixin],
    props: {
        refund: {
            type: Object,
            default: {}
        },
        items: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            printCheck: true,
            translate,
            isSubmitted: false,
            focusInField: null
        }
    },
    validations: {
        refund: {
            DebtPaymentCurrencyValue: {
                Value: {
                    required,
                    decimal,
                    minValue: minValue(0),
                }
            },
            CashbackCurrencyValue: {
                Value: {
                    required,
                    decimal,
                    minValue: minValue(0),
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            defaultEmployee: 'getDefaultEmployee',
            checkedAccessToDebtSale: "getCheckedAccessToDebtSale"
        }),
        total() {
            let total = 0;
            this.items.forEach(item => {
                let value = item.PriceCurrencyValue.Rate ? math.multiply(item.PriceCurrencyValue.Value, item.PriceCurrencyValue.Rate) :
                    item.PriceCurrencyValue.Value;
                total += math.multiply(value, item.Quantity);
            });
            return total;
        },
    },
    methods: {
        onFocusToPaymentInput(PaymentCurrencyValue, field) {
            if (!PaymentCurrencyValue.Value || PaymentCurrencyValue.Value === '0') PaymentCurrencyValue.Value = '';
            if (field) this.focusInField = field;
            else this.focusInField = null;
        },
        addNum(key) {
            if (this.focusInField && this.focusInField === 'CashbackCurrencyValue') {
                this.refund.CashbackCurrencyValue.Value = String(this.refund.CashbackCurrencyValue.Value + key).slice(0);
            } else if (this.focusInField && this.focusInField === 'DebtPaymentCurrencyValue') {
                this.refund.DebtPaymentCurrencyValue.Value = String(this.refund.DebtPaymentCurrencyValue.Value + key).slice(0);
            } else {
                this.refund.CashbackCurrencyValue.Value = String(this.refund.CashbackCurrencyValue.Value + key).slice(0);
            }
        },
        clear() {
            this.refund.CashbackCurrencyValue.Value = 0;
            this.refund.DebtPaymentCurrencyValue.Value = 0;
            this.focusInField = null;
        },
        toNextField() {
            let vm = this;
            if (vm.focusInField === 'CashbackCurrencyValue') {
                vm.focusInField = 'CashbackCurrencyValue'
            } else if (vm.focusInField === 'DebtPaymentCurrencyValue') {
                vm.focusInField = 'DebtPaymentCurrencyValue'
            }
        },
        del() {
            if (this.focusInField && this.focusInField === 'CashbackCurrencyValue') {
                this.refund.CashbackCurrencyValue.Value = this.refund.CashbackCurrencyValue.Value.slice(0, this.refund.CashbackCurrencyValue.Value.length - 1);
            } else if (this.focusInField && this.focusInField === 'DebtPaymentCurrencyValue') {
                this.refund.DebtPaymentCurrencyValue.Value = this.refund.DebtPaymentCurrencyValue.Value.slice(0, this.refund.DebtPaymentCurrencyValue.Value.length - 1);
            }
        },
        saveRefund() {
            let vm = this;
            this.$v.refund.$touch();
            if (!vm.$v.refund.$invalid) {
                if (!vm.refund.RefundId || vm.isSubmitted)
                    return;
                vm.isSubmitted = true;
                RefundService.edit(vm.$store.getters.selectedWarehouse.WarehouseId, vm.refund.RefundId, {refund: vm.refund}).then((refund) => {
                        vm.isSubmitted = false;
                        if (this.printCheck) {
                            Downloader.downloadRefundPdf(refund, true);
                        }
                        vm.$emit('success');
                    }, () => {
                        vm.isSubmitted = false;
                    }
                ).catch(err=>{
                    vm.isSubmitted = false;
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
