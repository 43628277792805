<template>
    <b-navbar class="h-100 my-primary px-3">
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item
                    class="mx-2">
                    <div class="text-white h5">
                        {{ translate.translate("frequentlyRepeated", "NoviyVozvrat") }}
                    </div>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto d-flex align-items-center">
                <b-nav-item>
                    <b-nav-item-dropdown right>
                        <template v-slot:button-content>
                            <span class="text-white">{{ currentUser }}</span>
                        </template>
                        <b-dropdown-item @click="logout">{{
                                translate.translate("frequentlyRepeated", "vixod")
                            }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import translate from "../../translation/translate";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RefundNavbarComponent",
    props: {
        Refund: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            translate
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
    },
    methods: {
        ...mapActions(["signOut"]),
        logout() {
            let vm = this;
            vm.signOut().then(() => {
                vm.$router.push("/user/login");
            });
        },
    }
}
</script>

<style scoped>

</style>