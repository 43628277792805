import BaseService from "../base/base.service";
import axios from "axios";

class RefundRegisterService extends BaseService {
    get modelName() {
        return "RegisterRefund";
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            let refund = response.data.Refund;
            let sortedItemsById = response.data.RefundItems.sortBy('RefundItemId');
            let sortedItemsDesc = sortedItemsById.reverse();

            return {refund: refund, refundItems: sortedItemsDesc}
        }).catch(() => {
        })
    }

    register({warehouseId, client, CashbackCurrencyValue, items, isApproved, DebtPaymentCurrencyValue, description}) {
        return axios.post(this.getCreateUrl(), {
            Refund: {
                ClientId: client.ClientId,
                CashbackCurrencyValue: {
                    Value: CashbackCurrencyValue.Value,
                    Rate: CashbackCurrencyValue.Rate,
                    CurrencyId: CashbackCurrencyValue.CurrencyId
                },
                IsApproved: isApproved,
                DebtPaymentCurrencyValue: {
                    Value: DebtPaymentCurrencyValue.Value,
                    Rate: DebtPaymentCurrencyValue.Rate,
                    CurrencyId: DebtPaymentCurrencyValue.CurrencyId
                },
                Description: description,
                WarehouseId: warehouseId
            },
            RefundItems: items.map((i) => {
                return {
                    CurrencyValue: {
                        Value: i.PriceCurrencyValue.Value,
                        Rate: i.PriceCurrencyValue.Rate,
                        CurrencyId: i.PriceCurrencyValue.CurrencyId
                    },
                    Quantity: i.Quantity,
                    ItemId: i.Item.Item.ItemId
                };
            })
        }).then((response) => {
            let refund = response.data.Refund;
            let refundItems = response.data.RefundItems;
            return {refund: refund, refundItems: refundItems}
        }).catch(() => {
        })
    }
}

export default new RefundRegisterService()
