import { render, staticRenderFns } from "./RefundPaymentFormComponent.vue?vue&type=template&id=0cf98675&scoped=true"
import script from "./RefundPaymentFormComponent.vue?vue&type=script&lang=js"
export * from "./RefundPaymentFormComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_underscore@1.13.6_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf98675",
  null
  
)

export default component.exports