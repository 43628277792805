<template>
    <b-table :responsive="true"
             :items="Items"
             hover
             :fields="fields"
             class="retail-items-table"
             ref="RetailItemsTable" show-empty>
        <template v-slot:cell(#)="scope">
            <small class="fw-bolder">{{ scope.index + 1 }}</small>
        </template>
        <template v-slot:cell(name)="scope">
            {{ scope.item.WarehouseItem.Item.Name | kitcut }}
        </template>
        <template v-slot:cell(price)="scope">
            <div class="d-flex align-items-center" style="width: 50px">
                {{
                    scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
                }}
                <IMaskPriceInput v-model="scope.item.PriceCurrencyValue.Value"
                                 ref="editingItemPrice"
                                 @blur="editItem(scope.item)"
                                 style="width: 120px;"
                                 class="border-0"
                                 @keyupEnter="editItem(scope.item)"
                                 @focus="($event)=>{$event.target.select()}"
                />
            </div>
        </template>
        <template v-slot:cell(quantity)="scope">
            <b-button-group class="w-100" size="sm">
                <b-button
                    style="max-width: 50px;"
                    class="border p-0 bg-white text-dark"
                    @click="decrement(scope.item)"
                >-
                </b-button>
                <b-form-input
                    v-model="scope.item.Quantity"
                    style="max-width: 60px;"
                    size="sm"
                    @blur="editItem(scope.item)"
                    @focus="($event)=>{$event.target.select()}"
                    class="border border-start-0 border-end-0 rounded-0 text-center"
                />
                <b-button
                    style="max-width: 50px;"
                    class="border p-0 bg-white text-dark"
                    @click="increment(scope.item)"
                >+
                </b-button>
            </b-button-group>
        </template>
        <template v-slot:cell(total)="scope">
            <div class="p-2" style="font-size: 15px">
                {{ scope.item.Total | decimal }}
                {{
                    scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
                }}
            </div>
        </template>
        <template v-slot:cell(actions)="scope">
            <div style="width: 5px;">
                <b-icon-trash class="cursor-pointer align-middle" @click="deleteItem(scope.item)"/>
            </div>
        </template>
        <template #empty="scope">
            <div class="text-center">
                <span>Отсканируйте штрих-код продукта</span>
            </div>
        </template>
    </b-table>
</template>

<script>
import IMaskPriceInput from "../imask/IMaskPriceInput";
import RefundItemService from "../../services/refund/refund.item.service";
import translate from "../../translation/translate";

export default {
    name: "RefundBasketListComponent",
    components: {IMaskPriceInput},
    props: {
        Items: {
            type: Array,
            default: []
        }
    },
    computed: {
        fields() {
            return [
                '#',
                {key: "name", label: "Названия товара", class: 'width-500'},
                {key: "price", label: "Цена"},
                {key: "quantity", label: "Количество"},
                {key: "total", label: "Общая сумма"},
                {key: "actions", label: ""}
            ]
        },
    },
    methods: {
        async editItem(refundItem) {
            let vm = this;
            await RefundItemService.edit(refundItem.RefundItemId, {
                refundItem: refundItem
            }).then((updatedItem) => {
                vm.clickedNumpad = null;
                vm.typingNumbers = '';
                vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                    title: translate.translate("frequentlyRepeated", "uspeshno"),
                    autoHideDelay: 300,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'success'
                });
            }).catch(err => {
            });
        },
        decrement(refundItem) {
            if (refundItem.Quantity <= 1)
                return;
            refundItem.Quantity = refundItem.Quantity - 1;
            this.editItem(refundItem);
        },
        increment(refundItem) {
            refundItem.Quantity = refundItem.Quantity + 1;
            this.editItem(refundItem);
        },
        deleteItem(refundItem) {
            let vm = this;
            vm.$bvModal.msgBoxConfirm(translate.translate("frequentlyRepeated", "deystviyaOtmenitPotomNelzya"), {
                title: translate.translate("frequentlyRepeated", "XotiteUdalit"),
                okTitle: translate.translate("frequentlyRepeated", "Da"),
                cancelTitle: translate.translate("frequentlyRepeated", "Net"),
                footerClass: 'p-3',
                hideHeaderClose: false,
                centered: false
            }).then(value => {
                if (value) {
                    RefundItemService.delete(refundItem.RefundItemId).then(() => {
                        let index = vm.Items.indexOf(refundItem);
                        if (index > -1) vm.Items.splice(index, 1);
                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                            title: translate.translate("frequentlyRepeated", "uspeshno"),
                            autoHideDelay: 300,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'success'
                        });
                    });
                }
            });
        }
    }
}
</script>

<style scoped>

</style>