<template>
    <b-row style="font-size: 90%">
        <b-col class="px-2" cols="12" style="height: 50px">
            <RefundNavbarComponent :Refund="Refund"/>
        </b-col>
        <b-col class="firstBlock px-2" lg="8" xl="9">
            <b-card class="soft-primary mt-2"
                    body-class="p-0 d-xl-flex align-items-xl-center">
                <div class="w-100 px-3 d-xl-flex align-items-xl-center justify-content-xl-between">
                    <div class="d-flex align-items-center px-2 my-2 my-lg-0">
                        <small class="mr-2">Склад:</small>
                        <WarehouseSearchElement/>
                    </div>
                    <div class="d-flex align-items-center px-2 my-2 my-lg-0">
                        <small class="mr-2">Клиент:</small>
                        <ClientSearchElement v-model="Refund.Client"/>
                    </div>
                    <div class="text-right my-2 my-lg-0">
                        <b-button size="sm" class="rounded-2 my-primary" @click="openManualAddItemModal">
                            Добавить товар вручную
                            <b-icon-plus class="cursor-pointer" font-scale="1.2"
                                         variant="black"></b-icon-plus>
                        </b-button>
                    </div>
                </div>
            </b-card>
            <b-card footer-class="p-0 d-flex bg-white"
                    class="shadow mt-2"
                    body-class="p-0 m-0"
                    style="height:calc(98vh - 93px);">
                <RefundBasketListComponent :Refund="Refund" :Items="Items" ref="RefundBasketListComponent"/>
            </b-card>
        </b-col>
        <b-col class="px-2" lg="4" xl="3">
            <RefundPaymentFormComponent :refund="Refund" :items="Items" ref="RefundPaymentFormComponent"
                                        @success="onSuccessCreatedRefund"/>
        </b-col>
        <ManualAddProduct :title='translate.translate("frequentlyRepeated", "NoviyVozvrat")' ref="manualAddProduct" @addProduct="addNewItemByManualForm"/>
    </b-row>
</template>

<script>
import RefundNavbarComponent from "../../../components/refund/RefundNavbarComponent";
import WarehouseSearchElement from "../../../components/searchElements/WarehouseSearchElement";
import ClientSearchElement from "../../../components/searchElements/ClientSearchElement";
import {mapGetters} from "vuex";
import RefundBasketListComponent from "../../../components/refund/RefundBasketListComponent";
import RefundPaymentFormComponent from "../../../components/refund/RefundPaymentFormComponent";
import WarehouseItemBarcodeService from "../../../services/warehouse/warehouse.item.barcode.service";
import RefundItemService from "../../../services/refund/refund.item.service";
import RefundRegisterService from "../../../services/refund/refund.register.service";
import translate from "../../../translation/translate";
import ManualAddProduct from "../../../components/sale/ManualAddProduct";

export default {
    name: "NewRefund",
    components: {
        ManualAddProduct,
        RefundPaymentFormComponent,
        RefundBasketListComponent, ClientSearchElement, WarehouseSearchElement, RefundNavbarComponent
    },
    data() {
        return {
            translate,
            Refund: null,
            NewItem: null,
            Items: [],
        }
    },
    computed: {
        ...mapGetters({
            defaultClient: 'getDefaultClient',
            selectedWarehouse: 'selectedWarehouse',
        })
    },
    methods: {
        onSuccessCreatedRefund() {
            this.Items = [];
            this.resetNewItem();
            this.resetRefund();
            this.$bvToast.toast(translate.translate("frequentlyRepeated", "OplachenoUspeshno"), {
                title: translate.translate("frequentlyRepeated", "uspeshno"),
                autoHideDelay: 5000,
                toaster: 'b-toaster-bottom-left',
                variant: 'success'
            })
        },
        resetNewItem() {
            this.NewItem = {
                CreatedAt: new Date(),
                PriceCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                Quantity: null,
                PackQuantity: null,
                Item: null,
                Username: null,
                BeforeState: null,
                Total: null
            }
        },
        resetRefund() {
            this.Refund = {
                RefundId: null,
                ClientId: null,
                CreatedAt: new Date(),
                IsApproved: false,
                Client: this.defaultClient,
                Username: null,
                Total: 0,
                Description: '',
                DebtPaymentCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                CashbackCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
            }
        },
        openManualAddItemModal() {
            this.$root.$emit('bv::show::modal', 'manual-add-modal', '#btnShow')
            this.$nextTick(function () {
                this.$refs.manualAddProduct.focus()
            })
        },
        addNewItemByManualForm(newItem) {
            this.NewItem.PriceCurrencyValue = newItem.PriceCurrencyValue;
            this.NewItem.Quantity = newItem.Quantity;
            this.NewItem.Item = newItem.Item;
            this.addNewItem();
        },
        addNewItem() {
            let vm = this;
            if (vm.Refund.RefundId) {
                let existItem = vm.Items.find(item => item.WarehouseItem.Item.ItemId === this.NewItem.Item.Item.ItemId);
                if (existItem) {
                    existItem.Quantity = +existItem.Quantity + +vm.NewItem.Quantity;
                    RefundItemService.edit(existItem.RefundItemId, {refundItem: existItem}).then((updatedItem) => {
                        vm.isSubmitted = false;
                        let findIndex = vm.Items.findIndex(item => item.RefundItemId === updatedItem.RefundItemId);
                        vm.$set(vm.Items, findIndex, updatedItem);
                        vm.resetNewItem();
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                    });
                } else {
                    RefundItemService.create({refund: this.Refund, refundItem: this.NewItem}).then(refundItem => {
                        vm.Items.unshift(refundItem);
                        vm.resetNewItem();
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                    });
                }
            } else {
                RefundRegisterService.register({
                    warehouseId: vm.selectedWarehouse.WarehouseId,
                    client: vm.Refund.Client,
                    CashbackCurrencyValue: {
                        Value: +(vm.Refund.CashbackCurrencyValue.Value || 0),
                        Rate: vm.Refund.CashbackCurrencyValue.Rate,
                        CurrencyId: vm.Refund.CashbackCurrencyValue.CurrencyId
                    },
                    items: [vm.NewItem],
                    isApproved: false,
                    DebtPaymentCurrencyValue: {
                        Value: +(vm.Refund.DebtPaymentCurrencyValue.Value || 0),
                        Rate: vm.Refund.DebtPaymentCurrencyValue.Rate,
                        CurrencyId: vm.Refund.DebtPaymentCurrencyValue.CurrencyId
                    },
                    description: vm.Refund.Description
                }).then(({refund, refundItems}) => {
                    vm.Refund = refund;
                    refundItems.forEach(refundItem => vm.Items.unshift(refundItem));
                    vm.resetNewItem();
                    if (vm.$refs.manualAddProduct)
                        vm.$refs.manualAddProduct.clear();
                    vm.$store.dispatch('loadActivePackage');
                }).catch(err => {
                });
            }
        },
        onBarcodeScanned(barcode) {
            let vm = this;
            WarehouseItemBarcodeService.get({
                barcode: barcode,
                warehouseId: vm.selectedWarehouse.WarehouseId,
            }).then((item) => {
                vm.NewItem.PriceCurrencyValue = item.Item.PriceCurrencyValue;
                vm.NewItem.Quantity = 1;
                vm.NewItem.Item = item;
                vm.addNewItem();
            }).catch(error => {
            });
        },
    },
    created() {
        this.resetRefund();
        this.resetNewItem();
    },
    mounted() {
        this.$barcodeScanner.init(this.onBarcodeScanned);
    },
    destroyed() {
        this.$barcodeScanner.destroy()
    },
}
</script>

<style scoped>

</style>