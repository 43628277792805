import BaseService from "../base/base.service";
import axios from 'axios';

class PurchaseItemService extends BaseService {
    get modelName() {
        return "RefundItem"
    }
    create({refund, refundItem}) {
        return axios.post(this.getCreateUrl(), {
            RefundId: refund.RefundId,
            ItemId: refundItem.Item.Item.ItemId,
            Quantity: +refundItem.Quantity,
            CurrencyValue: {
                Value: +refundItem.PriceCurrencyValue.Value,
                Rate: refundItem.PriceCurrencyValue.Rate,
                CurrencyId: refundItem.PriceCurrencyValue.CurrencyId
            },
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id));
    }

    edit(refundItemId, {refundItem}) {
        return axios.put(this.getEditUrl(refundItemId), {
            ItemId: refundItem.WarehouseItem.Item.ItemId,
            Quantity: +refundItem.Quantity,
            CurrencyValue: {
                Value: +refundItem.PriceCurrencyValue.Value,
                Rate: refundItem.PriceCurrencyValue.Rate,
                CurrencyId: refundItem.PriceCurrencyValue.CurrencyId
            },
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new PurchaseItemService()
