import axios from "axios";
import BaseService from "../base/base.service";

class RefundService extends BaseService {
    get modelName() {
        return "Refund"
    }

    getAll({warehouse, skip, limit, query, sort, desc, clientId, startDate, endDate, isApproved}) {
        let params = {
            warehouse: warehouse,
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
            skip: skip,
            limit: limit,
            client: clientId ? clientId : null,
            start: this._getDateDDMMYYYFormat(startDate),
            end: this._getDateDDMMYYYFormat(endDate),
            isApproved: (typeof (isApproved) === "undefined" || isApproved === null) ? null : !!isApproved
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id));
    }

    edit(warehouseId, refundId, {refund}) {
        return axios.put(this.getEditUrl(refundId), {
            ClientId: refund.Client.ClientId,
            CashbackCurrencyValue: {
                Value: refund.CashbackCurrencyValue.Value,
                Rate: refund.CashbackCurrencyValue.Rate,
                CurrencyId: refund.CashbackCurrencyValue.CurrencyId
            },
            DebtPaymentCurrencyValue: {
                Value: refund.DebtPaymentCurrencyValue.Value,
                Rate: refund.DebtPaymentCurrencyValue.Rate,
                CurrencyId: refund.DebtPaymentCurrencyValue.CurrencyId
            },
            Description: refund.Description,
            IsApproved: true,
            WarehouseId: warehouseId
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new RefundService()
