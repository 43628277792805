<template>
    <div>
        <b-card class="soft-primary my-2">
            <b-row>
                <b-col md="8">
                    <div class="d-flex align-items-center">
                        <b-input :placeholder='translation.translate("frequentlyRepeated", "Poisk")'
                                 v-model="search"
                                 autofocus
                                 @input="searchDebounce()"/>
                        <b-button :to="{name: 'create-refund'}" size="sm" style="width: 200px" class="ml-3 my-primary">
                            {{translation.translate("frequentlyRepeated", "NoviyVozvrat")}}
                        </b-button>
                    </div>
                </b-col>
                <b-col md="4" class="ml-auto text-right">
                    <TableLimit :limit="limit" @newLimit="onChangeLimit"/>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col>
                <b-card class="mb-4">
                    <b-overlay :show="isLoadedRefunds" spinner-variant="primary">
                        <b-table
                            hover
                            small
                            :responsive="true"
                            ref="datatable"
                            :items="refunds"
                            :fields="fields"
                            sort-icon-left
                            no-sort-reset
                            :sort-by="sortBy"
                            :sort-desc="sortDesc"
                            @sort-changed="sortingChanged"
                            :no-footer-sorting="true"
                            show-empty
                            class="hp-data-table position-relative"
                            :empty-text='translation.translate("frequentlyRepeated","netDanniyx")'
                        >
                            <template v-slot:cell(#)="scope">
                                {{ (page - 1) * limit + scope.index + 1 }}
                            </template>
                            <template v-slot:cell(client)="scope">
                                {{ scope.item.Client.Name }}
                            </template>
                            <template v-slot:cell(isApproved)="scope">
                                <b-badge pill :variant="scope.item.IsApproved ? 'success' : 'warning'">{{
                                        scope.item.IsApproved ?
                                            translation.translate("frequentlyRepeated", "podverjden") : translation.translate("frequentlyRepeated", "nepodverjden")
                                    }}
                                </b-badge>
                            </template>
                            <template v-slot:cell(debtpaymentammount)="scope">
                                {{ scope.item.DebtPaymentCurrencyValue.Value | division | decimal }}
                                {{
                                    scope.item.DebtPaymentCurrencyValue && scope.item.DebtPaymentCurrencyValue.Currency ? scope.item.DebtPaymentCurrencyValue.Currency.Name : ''
                                }}
                            </template>
                            <template v-slot:cell(cashbackammount)="scope">
                                {{ scope.item.CashbackCurrencyValue.Value | division | decimal }}
                                {{
                                    scope.item.CashbackCurrencyValue && scope.item.CashbackCurrencyValue.Currency ? scope.item.CashbackCurrencyValue.Currency.Name : ''
                                }}
                            </template>
                            <template v-slot:cell(createdAt)="scope">{{ scope.item.CreatedAt | datetime }}
                            </template>
                            <template v-slot:cell(total)="scope">
                                {{ scope.item.Total | division | decimal }}
                                {{
                                    selectedCurrency ? selectedCurrency.Name : ''
                                }}
                            </template>
                            <template v-slot:cell(account)="scope">{{ scope.item.Username }}</template>
                            <template v-slot:foot(empty)>
                                <h6 class="text-center">{{
                                        translation.translate("frequentlyRepeated", "netDanniyx")
                                    }}</h6>
                            </template>
                        </b-table>
                        <Pagination :page="page" :length="refunds.length" :limit="limit" @prevPage="onPrev()"
                                    @nextPage="onNext()"></Pagination>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import RefundService from "../../../services/refund/refund.service";
import {mapGetters} from "vuex";
import translation from "../../../translation/translate";
import Pagination from "../../../components/additional/Pagination";
import TableLimit from "../../../components/TableLimit";
import {debounce} from "underscore";

export default {
    name: "AllRefunds",
    components: {TableLimit, Pagination},
    data() {
        return {
            translation,
            isLoadedRefunds: false,
            refunds: [],
            sortDesc: !(this.$route.query && this.$route.query.sortDesc === 'false'),
            sortBy: this.$route.query && this.$route.query.sortBy ? this.$route.query.sortBy : 'createdAt',
            limit: localStorage.getItem('limit') || '10',
            search: this.$route.query && this.$route.query.search ? this.$route.query.search : '',
            page: this.$route.query && this.$route.query.page ? +this.$route.query.page : 1,
            startDate: this.$route.query && this.$route.query.startDate ? new Date(+this.$route.query.startDate) : new Date(),
            endDate: this.$route.query && this.$route.query.endDate ? new Date(+this.$route.query.endDate) : new Date(),
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            selectedWarehouse: "selectedWarehouse"
        }),
        fields() {
            return [
                '#',
                {
                    key: "client",
                    label: translation.translate("frequentlyRepeated", "klient"),
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: "total",
                    label: translation.translate("frequentlyRepeated", "ObshayaSummaPrixoda"),
                    sortable: true,
                },
                {
                    key: "isApproved",
                    label: translation.translate("frequentlyRepeated", "StatusVozvrata"),
                    sortable: false,
                },
                {
                    key: "debtpaymentammount",
                    label: translation.translate("frequentlyRepeated", "SoSchetaKlienta"),
                    sortable: true,
                },
                {
                    key: "cashbackammount",
                    label: translation.translate("frequentlyRepeated", "oplataNalichnimi"),
                    sortable: true,
                },
                {
                    key: "account",
                    label: translation.translate("frequentlyRepeated", "prodavets"),
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: translation.translate("frequentlyRepeated", "dataVozvrata"),
                    sortable: true,
                }
            ]
        },
    },
    methods: {
        searchDebounce: debounce(function () {
            this.page = 1;
            this.update();
        }, 1000),
        onChangeLimit(selectedLimit) {
            let vm = this;
            vm.limit = selectedLimit;
            vm.update();
        },
        onPrev() {
            this.page = this.page - 1;
            this.update();
        },
        onNext() {
            this.page = this.page + 1;
            this.update();
        },
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.update()
        },
        loadRefunds() {
            this.isLoadedRefunds = true;
            RefundService.getAll({
                skip: (this.page - 1) * +this.limit,
                limit: this.limit,
                query: this.search,
                sort: this.sortBy,
                desc: this.sortDesc,
                clientId: null,
                startDate: this.startDate,
                endDate: this.endDate,
                warehouse: this.selectedWarehouse.WarehouseId,
                isApproved: null
            }).then(refunds => {
                this.isLoadedRefunds = false;
                this.refunds = refunds;
            });
        },
    },
    mounted() {
        this.loadRefunds();
    }
}
</script>

<style scoped>

</style>